<template>
  <HeadlessTransitionRoot
    :show="show"
    appear
    as="template"
    @after-leave="emit('cleanup')"
  >
    <HeadlessDialog unmount as="div" class="text-grays-darkest relative z-50">
      <HeadlessTransitionChild
        as="template"
        enter="duration-200 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0"
          :class="props.overlayClass"
          aria-hidden="true"
        />
      </HeadlessTransitionChild>
      <div
        id="dialog-wrapper"
        class="fixed inset-0 overflow-y-auto overflow-x-hidden"
      >
        <div class="flex min-h-full items-center justify-center p-4">
          <HeadlessTransitionChild
            as="template"
            enter="duration-200 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <HeadlessDialogPanel
              id="dialog-panel"
              class="relative max-h-screen w-full overflow-y-auto rounded-lg bg-white px-4"
              :class="
                twMerge(
                  twJoin(
                    props.size === 'small' && 'py-8 md:max-w-md md:px-8',
                    props.size === 'medium' && 'pb-8 pt-4 md:max-w-2xl md:px-8',
                    props.size === 'large' && 'pb-8 pt-4 md:max-w-5xl md:px-8',
                  ),
                  contentClass,
                )
              "
            >
              <button v-if="showCloseIcon" class="flex" @click="emit('close')">
                <img
                  src="@/assets/images/close.svg"
                  class="text-dark-grey absolute right-4 top-7 z-10 h-4 w-4"
                  :class="{
                    'md:right-6': props.size === 'small',
                    'md:right-8':
                      props.size === 'medium' || props.size === 'large',
                  }"
                />
              </button>
              <HeadlessDialogTitle>
                <slot name="title" />
              </HeadlessDialogTitle>
              <HeadlessDialogDescription>
                <slot name="body" />
              </HeadlessDialogDescription>
            </HeadlessDialogPanel>
          </HeadlessTransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<script setup lang="ts">
import { twMerge, twJoin } from 'tailwind-merge'
import type { PropType } from 'vue'

type Size = 'small' | 'medium' | 'large'

const emit = defineEmits(['close', 'cleanup'])

const props = defineProps({
  show: {
    required: true,
    type: Boolean,
  },
  size: {
    required: false,
    type: String as PropType<Size>,
    default: () => 'medium',
  },
  overlayClass: {
    required: false,
    type: String,
    default: () => 'bg-black/30',
  },
  contentClass: {
    required: false,
    type: String,
    default: () => '',
  },
  showCloseIcon: {
    type: Boolean,
    default: true,
  },
})
</script>
