<script setup lang="ts">
import { computed } from 'vue'
import { twMerge } from 'tailwind-merge'

export interface Props {
  type?: 'primary' | 'secondary' | 'link' | 'gray'
  disabled?: boolean
  disabledGrayState?: boolean
  loading?: boolean
  class: string
}

interface ZnButtonEvents {
  (e: 'click', value: Event): void
}

const emits = defineEmits<ZnButtonEvents>()
const onClickHandler = (e: MouseEvent) => {
  // TODO
  // @ts-ignore
  if (!props.disabled && !props.loading && !props.disabledGrayState) {
    emits('click', e)
  }
}

const props = withDefaults(defineProps<Props>(), {
  type: 'primary',
  loading: false,
  disabledGrayState: false,
  class: '',
})

const statusClass = computed(() => {
  if (props.disabled) {
    return 'text-grays-light border border-grays-light cursor-not-allowed'
  }
  if (props.disabledGrayState) {
    return props.type === 'link'
      ? 'text-grays-medium cursor-not-allowed border-0 p-0 underline'
      : 'text-white cursor-not-allowed border-0 bg-grays-light'
  }
  if (props.loading) {
    return 'text-white bg-teal-primary cursor-not-allowed'
  }
  if (props.type === 'primary') {
    return 'text-white bg-teal-primary cursor-pointer'
  }
  if (props.type === 'secondary') {
    return 'text-grays-darkest font-semibold border border-teal-primary cursor-pointer'
  }
  if (props.type === 'link') {
    return 'text-teal-primary font-bold underline cursor-pointer p-0'
  }
  if (props.type === 'gray') {
    return 'text-white font-semibold bg-grays-light cursor-not-allowed'
  }
  return ''
})

const buttonClass = computed(() => {
  const baseClass = 'w-fit rounded-lg px-5 py-4 text-lg leading-6'

  return twMerge(baseClass, statusClass.value, props.class)
})
</script>
<template>
  <div :class="buttonClass" @click="onClickHandler">
    <img
      v-if="loading"
      class="mx-auto"
      src="@/assets/images/button-loader.svg"
    />
    <template v-else>
      <slot />
    </template>
  </div>
</template>
